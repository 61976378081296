<main class="content px-2">

  <h1 class="sr-only" i18n="Document title">TFI Journey Planner Widget</h1>

  <form [formGroup]="journeyForm" (submit)="submit()">

    <div class="position-relative">

      <tfi-jpw-location-field [control]="getControlByName('origin')" id="origin"
        label="From" [submitted]="submitted">
      </tfi-jpw-location-field>

      <tfi-jpw-location-field [control]="getControlByName('destination')" id="destination"
        label="To" [submitted]="submitted">
      </tfi-jpw-location-field>

      <button type="button" class="swap-locations position-absolute"
        (click)="swapLocations()" aria-label="Swap origin and destination"
        color="white" matRipple color="green"
        [disabled]="!getControlByName('origin').value && !getControlByName('destination').value">
        <svg width="20" height="20" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="#00B273" d="M9.814 1.718L5.105 6.425a.997.997 0 000 1.413.997.997 0 001.414 0L8.81 5.546v7.587a1 1 0 102.002 0V5.546l2.292 2.292a1.001 1.001 0 001.416-1.413L9.814 1.718zm4.374 20.566l4.707-4.706a.997.997 0 000-1.414.997.997 0 00-1.413 0l-2.293 2.29V10.87a1 1 0 10-2.002 0v7.588l-2.292-2.293a1.001 1.001 0 00-1.416 1.413l4.71 4.707z" />
        </svg>
      </button>
    </div>

    <tfi-jpw-modes [modes]="getControlByName('modes')" [submitted]="submitted">
    </tfi-jpw-modes>

    <tfi-jpw-departure-type [control]="getControlByName('departureType')"
      [submitted]="submitted"></tfi-jpw-departure-type>

    <tfi-jpw-date-time-field
      [hidden]="getControlByName('departureType').value === 'LEAVE_NOW'"
      [control]="getControlByName('dateTime')" [submitted]="submitted"
      [label]="getControlByName('departureType').value">
    </tfi-jpw-date-time-field>

    <button type="submit" matRipple class="btn btn-primary btn-lg d-block w-100"
      i18n="Form submit button">
      Plan my Journey
    </button>

  </form>

</main>
