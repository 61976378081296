<div class="form-group d-flex m-0">
  <div class="form-group date-picker-element position-relative w-100 pe-1">
    <input id="date" [formControl]="control" placeholder="Select"
      class="form-control" autocomplete="false" matInput
      [ngxMatDatetimePicker]="picker">
    <label for="date" hidden>
      <ng-container *ngIf="label === 'LEAVE_NOW'"
        i18n="Departure type leave now">Leave now</ng-container>
      <ng-container *ngIf="label === 'LEAVE_AFTER'"
        i18n="Departure type depart at">Depart at</ng-container>
      <ng-container *ngIf="label === 'ARRIVE_BY'"
        i18n="Departure type arrive by">Arrive by</ng-container>
    </label>
    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [showSpinners]="config.showSpinners"
      [showSeconds]="config.showSeconds" [stepHour]="config.stepHour"
      [stepMinute]="config.stepMinute" [stepSecond]="config.stepSecond"
      [touchUi]="config.touchUi" [color]="config.color"
      [disableMinute]="config.disableMinute" [hideTime]="config.hideTime"
      [enableMeridian]="config.enableMeridian"></ngx-mat-datetime-picker>
  </div>
  <div class="d-flex w-100 ps-1">
    <div class="form-group w-100 pl-1">
      <mat-select class="form-control" [value]="hour"
        (selectionChange)="changeHour($event)">
        <mat-option *ngFor="let hour of hours" [value]="hour">
          {{ hour }}
        </mat-option>
      </mat-select>
    </div>
    <div class="form-group w-100 pl-1">
      <mat-select class="form-control" [value]="min"
        (selectionChange)="changeMin($event)">
        <mat-option *ngFor="let min of mins" [value]="min">
          {{ min }}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>
