import { Injectable } from '@angular/core';
import { JourneyFormValue } from '../app';

@Injectable()
export class FormatJourneyService {

  format(values: JourneyFormValue): string {
    const strings = <string[]>[];

    for (let key in values) {
      switch (key) {
        case 'origin':
        case 'destination':
          strings.push(`${key}=${encodeURIComponent(JSON.stringify(values[key]))}`);
          break;
        case 'dateTime':
          strings.push(`${key}=${values[key].toISOString()}`);
          break;
        case 'modes':
          const selected = [];

          for (let mode in values[key]) {
            if (values[key][mode]) {
              selected.push(mode);
            }
          }

          if (selected) {
            strings.push(`${key}=${encodeURIComponent(JSON.stringify(selected))}`);
          }
          break;
        default:
          strings.push(`${key}=${values[key]}`);
      }
    }

    return strings.join('&');
  }

}
