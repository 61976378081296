import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { LocationsService } from '../services/locations.service';
import { OJPLocation, OJPError } from '../app';

@Component({
  selector: 'tfi-jpw-location-field',
  templateUrl: './location-field.component.html',
  styleUrls: ['./location-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationFieldComponent implements OnInit, OnDestroy {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() submitted: boolean;
  @Input() id: string;

  searching: boolean;
  suggestions: OJPLocation[];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private locations: LocationsService) { }

  ngOnInit(): void {
    this.control.valueChanges.pipe(map((query: string) => query),
        filter((query: string) => query.length > 2), debounceTime(250),
        distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((text: string) => {
        this.searching = true;
        this.suggestions = [];
        this.getLocations(text)
      });
  }

  private getLocations(query: string) {
    this.locations.get(query).pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (suggestions: OJPLocation[]): void => {
          this.searching = false;
          this.suggestions = suggestions;
        },
        (error: OJPError) => {
          this.searching = false;
          throwError(error);
        }
      );
  }

  displayFn(item: any): string {
    return item ? item.name : '';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

}
