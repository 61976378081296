<div class="departure-type btn-group mb-3 w-100 small"
  role="group" i18n-label aria-label="Departure type">
  <button matRipple class="btn btn-outline-primary btn-lg px-0"
    (click)="changeType('LEAVE_NOW')" [disabled]="control.value === 'LEAVE_NOW'"
    i18n="Departure type leave now" type="button">Leave now</button>
  <button matRipple class="btn btn-outline-primary px-0"
    (click)="changeType('LEAVE_AFTER')" [disabled]="control.value === 'LEAVE_AFTER'"
    i18n="Departure type depart at" type="button">Depart at</button>
  <button matRipple class="btn btn-outline-primary px-0"
    (click)="changeType('ARRIVE_BY')" [disabled]="control.value === 'ARRIVE_BY'"
    i18n="Departure type arrive by" type="button">Arrive by</button>
</div>
<input type="hidden" [formControl]="control">
