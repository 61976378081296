import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppComponent } from './app.component';
import { LocationFieldComponent } from './location-field/location-field.component';
import { DateTimeFieldComponent } from './date-time-field/date-time-field.component';
import { LocationsService } from './services/locations.service';
import { ModesFieldComponent } from './modes-field/modes-field.component';
import { DepartureTypeFieldComponent } from './departure-type-field/departure-type-field.component';
import { FormatJourneyService } from './services/format-journey.service';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "l, LTS"
  },
  display: {
    dateInput: "l",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LocationFieldComponent,
    DateTimeFieldComponent,
    ModesFieldComponent,
    DepartureTypeFieldComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatRippleModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxMatMomentModule,
    MatNativeDateModule,
    MatSelectModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    OverlayModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule
  ],
  providers: [
    LocationsService,
    FormatJourneyService,
    {
      provide: LOCALE_ID,
      useValue: "en-GB"
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
