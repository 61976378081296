import { Component, ViewEncapsulation, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OJPDepartureType } from '../app';

@Component({
  selector: 'tfi-jpw-departure-type',
  templateUrl: './departure-type-field.component.html',
  styleUrls: ['./departure-type-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepartureTypeFieldComponent {

  @Input() control: FormControl;
  @Input() submitted: boolean;

  changeType(type: OJPDepartureType): void {
    this.control.patchValue(type, { onlySelf: true, emitEvent: false });
  }

}
