import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JourneyModesValues } from '@app/app';

@Component({
  selector: 'tfi-jpw-modes',
  templateUrl: './modes-field.component.html',
  styleUrls: ['./modes-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModesFieldComponent implements OnInit, OnDestroy {

  @Input() modes: FormGroup;
  @Input() submitted: boolean;

  oldState: JourneyModesValues;

  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.oldState = this.modes.value;

    this.modes.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((newState: JourneyModesValues) => {
        this.enforceRestrictions(newState, this.oldState);
      });
  }

  /**
   * Cycle and walk only work independently so toggle the values as needed
   */
  enforceRestrictions(newState: JourneyModesValues,
    oldState: JourneyModesValues): void {
    if ((!oldState.CYCLE && newState.CYCLE) || (!oldState.WALK && newState.WALK)) {
      if (oldState.BUS) {
        this.modes.controls.BUS.patchValue(false, { emitEvent: false });
      }
      if (oldState.TRAIN) {
        this.modes.controls.TRAIN.patchValue(false, { emitEvent: false });
      }
      if (oldState.METRO) {
        this.modes.controls.METRO.patchValue(false, { emitEvent: false });
      }
      if (newState.WALK && oldState.CYCLE) {
        this.modes.controls.CYCLE.patchValue(false, { emitEvent: false });
      }
      if (newState.CYCLE && oldState.WALK) {
        this.modes.controls.WALK.patchValue(false, { emitEvent: false });
      }
    } else {
      if (newState.BUS || newState.TRAIN || newState.METRO) {
        if (oldState.CYCLE) {
          this.modes.controls.CYCLE.patchValue(false, { emitEvent: false });
        }
        if (oldState.WALK) {
          this.modes.controls.WALK.patchValue(false, { emitEvent: false });
        }
      }
    }

    this.oldState = this.modes.value;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
