<div class="form-group form-static-label mb-3 position-relative">
  <label for="location">
    <ng-container *ngIf="label === 'From'" i18n="Origin label">
      From
    </ng-container>
    <ng-container *ngIf="label === 'To'" i18n="Destination label">
      To
    </ng-container>
  </label>
  <input
    class="form-control"
    [formControl]="control"
    matInput
    [matAutocomplete]="auto"
    required="true"
    [ngClass]="{ 'is-invalid': submitted && control.errors }"
    [id]="id"
    [name]="id"
  >
  <button class="location-clear" type="button" aria-label="Clear location"
    (click)="control.patchValue('')" [hidden]="!control.value || searching">
    <svg width="20" height="20" viewBox="0 0 100 100" aria-hidden="true">
      <path d="M67.8 73.3L26.7 32.2c-1.5-1.5-1.5-4 0-5.5l0 0c1.5-1.5 4-1.5 5.5 0l41.1 41.1c1.5 1.5 1.5 4 0 5.5l0 0C71.8 74.9 69.3 74.9 67.8 73.3z"></path>
      <path d="M26.7 67.8l41.1-41.1c1.5-1.5 4-1.5 5.5 0l0 0c1.5 1.5 1.5 4 0 5.5L32.2 73.3c-1.5 1.5-4 1.5-5.5 0l0 0C25.1 71.8 25.1 69.3 26.7 67.8z"></path>
    </svg>
  </button>
  <mat-spinner [hidden]="!searching" class="position-absolute translate-middle"
    diameter="18" defaultColor="secondary"></mat-spinner>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
  >
    <mat-option
      *ngFor="let item of suggestions"
      [value]="item">
      <svg viewBox="0 0 100 100" aria-hidden="true" width="16" height="16">
        <path d="M50 5C32 5 17 20 17 38 17 63 50 95 50 95s33-32 33-57C83 20 68 5 50 5zM50 53c-8 0-15-7-15-15 0-8 7-15 15-15s15 7 15 15C65 46 58 53 50 53z"></path>
      </svg>
      {{ item.name }}
    </mat-option>
  </mat-autocomplete>
</div>
