import { LOCALE_ID, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLocaleData, } from '@angular/common';
import localeGb from '@angular/common/locales/en-GB';
import localeGa from '@angular/common/locales/ga';

registerLocaleData(localeGb, 'en-GB');
registerLocaleData(localeGa, 'ga');

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule, {
    providers: [{ provide: LOCALE_ID, useValue: 'en-GB' }]
  })
  .catch(err => console.error(err));
});
