import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import moment from 'moment';
import { FormatJourneyService } from './services/format-journey.service';
import { JourneyFormValue, OJPLocation } from './app';
import { environment } from '../environments/environment';

@Component({
  selector: 'tfi-jpw-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  submitted: boolean;

  journeyForm = new FormGroup({
    origin: new FormControl(null, [Validators.required]),
    destination: new FormControl(null, [Validators.required]),
    dateTime: new FormControl(moment(), [Validators.required]),
    departureType: new FormControl('LEAVE_NOW', [Validators.required]),
    modes: new FormGroup({
      BUS: new FormControl(true, []),
      TRAIN: new FormControl(true, []),
      METRO: new FormControl(false, []),
      CYCLE: new FormControl(false, []),
      WALK: new FormControl(false, [])
    })
  });

  constructor(@Inject(DOCUMENT) private document: Document,
    private formatter: FormatJourneyService,
    private overlayContainer: OverlayContainer) {
    this.submitted = false;
    overlayContainer.getContainerElement().classList.add('tfi-ojpw');
  }

  getControlByName(name: string): FormControl {
    return this.journeyForm.controls[name] as FormControl;
  }

  swapLocations(): void {
    const origin: OJPLocation = this.journeyForm.controls.origin.value;
    const destination: OJPLocation = this.journeyForm.controls.destination.value;
    this.journeyForm.controls.origin.patchValue(destination);
    this.journeyForm.controls.destination.patchValue(origin);
  }

  submit = (): void => {
    this.submitted = true;

    // Debugging.
    // console.log(this.journeyForm);
    // console.log(this.formatter.format(this.journeyForm.value));
    // return;

    if (this.journeyForm.invalid) {
      return;
    }

    const values: JourneyFormValue = this.journeyForm.value;
    const queryString: string = this.formatter.format(values);

    const link = this.document.createElement('a');
    link.target = '_parent';
    link.href = `${environment.journeyPlannerUrl}?${queryString}`;
    link.click();
    link.remove();
  }

}
