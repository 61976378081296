<div class="form-group modes">

  <div class="form-check form-check-inline text-center" matTooltip="BUS & COACH"
    matTooltipPosition="above" matTooltipHideDelay="250"
    matTooltipClass="mode-tooltip">
    <input class="form-check-input" type="checkbox" id="BUS" value="BUS"
      [formControl]="modes.controls.BUS">
    <label class="form-check-label" for="BUS" matRipple>
      BUS & COACH
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" aria-hidden="true" width="44" height="44">
        <path d="M155.18 106.88H42.48c-5.38 0-6.53-3.81-6-7.64l6.16-51.2c.84-4.8 2.51-8.03 8.84-8.03h94.71c6.33 0 8 3.24 8.84 8.03l6.16 51.2c.51 3.83-.64 7.64-6.01 7.64m-4.73 46.73c-5.98 0-10.81-4.85-10.81-10.8 0-5.97 4.83-10.81 10.81-10.81 5.99 0 10.79 4.83 10.79 10.81 0 5.96-4.8 10.8-10.79 10.8m-103.25 0c-5.99 0-10.79-4.85-10.79-10.8 0-5.97 4.8-10.81 10.79-10.81 5.98 0 10.81 4.83 10.81 10.81 0 5.96-4.84 10.8-10.81 10.8M58.23 18.43h81.19c7.34 0 7.34 10.8 0 10.8H58.23c-7.34 0-7.34-10.8 0-10.8m108.14 10.73c-2.05-9.87-8.43-13.81-18.1-17.82-9.55-3.89-30.53-8.81-47.39-8.96h-4.1c-16.86.15-37.84 5.07-47.39 8.96-9.67 4.01-16.05 7.95-18.1 17.82 0 0-8.06 72.64-8.06 74.32v48.05h.04c.26 8.65 5.26 16.15 12.49 19.98l.05 14.45c0 14.43 21.38 14.43 21.38 0v-11.74h83.29v11.74c0 14.43 21.38 14.43 21.38 0l.05-14.45c7.24-3.83 12.23-11.33 12.49-19.98h.04v-48.05c-.01-1.68-8.07-74.32-8.07-74.32"></path>
      </svg>
    </label>
  </div>

  <div class="form-check form-check-inline text-center" matTooltip="TRAIN"
    matTooltipPosition="above" matTooltipHideDelay="250"
    matTooltipClass="mode-tooltip">
    <input class="form-check-input" type="checkbox" id="TRAIN" value="TRAIN"
      [formControl]="modes.controls.TRAIN">
    <label class="form-check-label" for="TRAIN" matRipple>
      TRAIN
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 200 200" aria-hidden="true">
        <path d="M120.19 22.31c5.33 0 9.64-4.34 9.64-9.71 0-5.39-4.3-9.72-9.64-9.72-5.4 0-9.8 4.33-9.8 9.72 0 5.36 4.4 9.71 9.8 9.71"></path>
        <path d="M95.79 22.31c5.37 0 9.74-4.34 9.74-9.71 0-5.39-4.36-9.72-9.74-9.72S86.1 7.21 86.1 12.6c0 5.36 4.31 9.71 9.69 9.71"></path>
        <path d="M138.98 148.11c-5.38 0-9.73-4.36-9.73-9.71 0-5.38 4.35-9.73 9.73-9.73 5.39 0 9.71 4.35 9.71 9.73a9.7 9.7 0 01-9.71 9.71M83.4 172.37l4.08-6.07h40.99l4.03 6.07H83.4zm-7.6-24.26a9.7 9.7 0 01-9.71-9.71c0-5.38 4.32-9.73 9.71-9.73 5.38 0 9.73 4.35 9.73 9.73 0 5.35-4.34 9.71-9.73 9.71m-9.71-75.29c0-8.02 6.56-14.57 14.58-14.57h53.44c8.02 0 14.58 6.56 14.58 14.57V87.4c0 8.02-6.56 14.58-14.58 14.58H80.68c-8.02 0-14.58-6.56-14.58-14.58V72.82zm24.39-33.65h33.83c6.6 0 6.6 9.72 0 9.72H90.48c-6.6 0-6.6-9.72 0-9.72m51.26 120.52c10.89-2.4 19.1-12.14 19.1-23.71v-82.6c0-13.36-10.93-24.29-24.29-24.29h-58.3c-13.36 0-24.29 10.93-24.29 24.29v82.6c0 11.93 8.73 21.9 20.1 23.9L49.1 197.25h17.58l8.56-12.74h65.33l8.47 12.74h17.58l-24.88-37.56z"></path>
      </svg>
    </label>
  </div>

  <div class="form-check form-check-inline text-center" matTooltip="TRAM & METRO"
    matTooltipPosition="above" matTooltipHideDelay="250"
    matTooltipClass="mode-tooltip">
    <input class="form-check-input" type="checkbox" id="METRO" value="METRO"
      [formControl]="modes.controls.METRO">
    <label class="form-check-label" for="METRO" matRipple>
      TRAM & METRO
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" height="100" width="44" height="44" aria-hidden="true">
        <path d="M122.76 160.02c-5.32 0-9.63-4.31-9.63-9.61 0-5.32 4.3-9.63 9.63-9.63 5.33 0 9.61 4.3 9.61 9.63a9.6 9.6 0 01-9.61 9.61m-43.3 0a9.6 9.6 0 01-9.61-9.61c0-5.32 4.28-9.63 9.61-9.63 5.33 0 9.63 4.3 9.63 9.63 0 5.3-4.3 9.61-9.63 9.61m-9.6-103.37c0-7.93 6.49-14.42 14.42-14.42h33.66c7.93 0 14.42 6.49 14.42 14.42v57.7c0 7.94-6.49 14.43-14.42 14.43H84.28c-7.93 0-14.42-6.49-14.42-14.43v-57.7zm29.61-45.18h3.26c4.09.03 8.4.43 12.62 1.02l-4.76 17.71H91.64l-4.76-17.71c4.21-.6 8.51-.99 12.59-1.02m20.88 18.73h-2.29l4.43-16.49c7.13 1.42 13.39 3.24 16.93 4.68.45.18.91.27 1.36.27a3.6 3.6 0 003.34-2.25c.75-1.84-.14-3.95-1.98-4.7-7.41-3.02-24.52-7.32-39.39-7.45h-3.32c-14.83.12-31.94 4.43-39.35 7.45-1.84.76-2.73 2.86-1.98 4.7a3.592 3.592 0 004.7 1.98c3.54-1.45 9.8-3.26 16.93-4.68l4.43 16.49h-2.29c-13.22 0-24.04 10.82-24.04 24.04v96.18c0 10.31 6.59 19.14 15.76 22.54l-15.76 23.68h17.41l8.47-12.47h34.81l8.47 12.47h17.41l-15.77-23.68c9.17-3.4 15.77-12.22 15.77-22.54V54.24c-.01-13.22-10.83-24.04-24.05-24.04"></path>
        <path d="M84.37 61.96h33.5c6.5 0 6.5-9.61 0-9.61h-33.5c-6.53-.01-6.53 9.61 0 9.61"></path>
      </svg>
    </label>
  </div>

  <div class="form-check form-check-inline border-primary border-left text-center"
    matTooltip="CYCLE" matTooltipPosition="above"
    matTooltipHideDelay="250" matTooltipClass="mode-tooltip">
    <input class="form-check-input" type="checkbox" id="CYCLE" value="CYCLE"
      [formControl]="modes.controls.CYCLE">
    <label class="form-check-label" for="CYCLE" matRipple>
      CYCLE
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.01 180.9" aria-hidden="true" width="44" height="44">
        <path d="M119.55 108.33l61.09-60.34 24.78 68.63a7.744 7.744 0 009.91 4.65 7.731 7.731 0 004.65-9.9L182.65 7.98a7.735 7.735 0 00-7.27-5.11h-19.82c-4.28 0-7.74 3.46-7.74 7.74 0 4.27 3.47 7.74 7.74 7.74h14.38l4.91 13.6-61.01 60.26-18.9-53.42c-1.42-4.03-5.84-6.13-9.87-4.71a7.726 7.726 0 00-4.71 9.87l31.67 89.5c1.12 3.18 4.11 5.15 7.29 5.15a7.726 7.726 0 007.29-10.31l-7.06-19.96z"></path>
        <path d="M85.62 29.74h.31c6.51-.07 29.31-.22 30.66-11.63.21-1.8-.34-3.61-1.51-5.08-1.33-1.62-3.3-2.63-5.17-2.63H69.27c-1.92 0-3.93 1.02-5.26 2.67-1.17 1.44-1.71 3.22-1.49 4.98 1.34 11.47 16.04 11.61 23.1 11.69M222.74 63.4a7.731 7.731 0 00-9 6.22c-.76 4.2 2.02 8.24 6.22 9.01 19.06 3.47 32.89 20.09 32.89 39.5 0 22.14-18.01 40.15-40.15 40.15-22.14 0-40.15-18.01-40.15-40.15 0-10.44 3.99-20.34 11.23-27.86a7.739 7.739 0 00-.2-10.94c-3.07-2.96-7.98-2.86-10.94.2-10.04 10.42-15.57 24.12-15.57 38.6 0 30.67 24.96 55.62 55.63 55.62s55.63-24.96 55.63-55.62c0-26.9-19.18-49.92-45.59-54.73M103.84 140.78a7.728 7.728 0 00-10.83 1.55 40.143 40.143 0 01-33.47 16.07c-10.72-.35-20.66-4.86-27.99-12.68-7.33-7.83-11.17-18.05-10.82-28.77.73-22.13 19.25-39.52 41.45-38.81 2.03.07 4.06.29 6.02.64 4.22.78 8.24-2 9.01-6.2.77-4.21-2.01-8.24-6.21-9.01-2.72-.5-5.52-.8-8.32-.9-30.73-1.05-56.42 23.12-57.42 53.77-.49 14.85 4.83 29.01 14.99 39.85 10.16 10.85 23.93 17.09 38.78 17.57.61.02 1.21.03 1.82.03 17.47 0 34.01-8.25 44.54-22.29 2.57-3.41 1.87-8.26-1.55-10.82"></path>
      </svg>
    </label>
  </div>

  <div class="form-check form-check-inline border-primary border-left text-center"
    matTooltip="WALK" matTooltipPosition="above"
    matTooltipHideDelay="250" matTooltipClass="mode-tooltip">
    <input class="form-check-input" type="checkbox" id="WALK" value="WALK"
      [formControl]="modes.controls.WALK">
    <label class="form-check-label" for="WALK" matRipple>
      WALK
      <svg height="44" width="44" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" aria-hidden="true">
        <circle cx="53.258" cy="9.573" r="8.073"></circle>
        <path d="M78.406,44.311c-2.243-0.388-11.224-1.944-11.725-2.111c-0.574-0.191,0,0-0.191-0.287c-0.19-0.287-8.025-16.815-8.79-17.675  c-0.764-0.86-2.866-3.058-5.827-4.013c-2.962-0.955-5.924,0.573-5.924,0.573s-14.713,8.408-15.668,8.885  c-0.956,0.478-1.815,2.198-1.815,2.198l-6.869,12.913c-0.563,0.709-0.901,1.604-0.901,2.58c0,2.295,1.861,4.156,4.156,4.156  c1.962,0,3.603-1.362,4.039-3.19l6.406-12.112l4.013-2.245c-0.287,0.86-3.965,18.774-3.965,18.774l-3.726,21.21L18.562,89.699  c-0.939,0.875-1.529,2.119-1.529,3.503c0,2.647,2.146,4.794,4.793,4.794c1.612,0,3.036-0.8,3.904-2.021  c2.392-2.812,14.424-16.954,15.585-18.331c1.29-1.528,1.624-2.292,1.815-3.295c0.191-1.004,2.341-13.949,2.341-13.949l12.324,12.325  V94.46h0.025c0.248,2.433,2.303,4.331,4.801,4.331c2.497,0,4.552-1.898,4.799-4.331h0.025c0,0-0.001-0.154-0.003-0.421  c0-0.024,0.003-0.049,0.003-0.073c0-0.031-0.004-0.062-0.004-0.094c-0.023-3.312-0.125-19.801,0.004-23.536  c0.143-4.155-1.339-5.063-1.673-5.445c-0.335-0.382-11.847-12.611-11.847-12.611l2.531-12.276c0,0,3.297,6.448,3.631,7.165  c0.335,0.716,1.386,2.007,2.483,2.245c1.1,0.239,14.81,3.01,14.81,3.01l0.006-0.029c0.201,0.03,0.405,0.062,0.615,0.062  c2.261,0,4.093-1.832,4.093-4.093C82.096,46.24,80.475,44.517,78.406,44.311z"></path>
      </svg>
    </label>
  </div>

</div>
