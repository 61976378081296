import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OJPLocation } from '../app';
import { Observable } from 'rxjs';

@Injectable()
export class LocationsService {

  constructor(private http: HttpClient) { }

  get(query: string): Observable<OJPLocation[]> {
    const uri = `${environment.tfiOjpLocations}?query=${query}&type[]=address&number_of_results=25`;
    return this.http.get<OJPLocation[]>(uri);
  }

}
