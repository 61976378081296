import { Component, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'tfi-jpw-date-time-field',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateTimeFieldComponent implements OnInit, OnDestroy {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() submitted: boolean;

  hours: string[];
  hour: string;
  mins: string[];
  min: string;
  now: Moment;

  config = {
    showSpinners: true,
    showSeconds: false,
    stepHour: 1,
    stepMinute: 1,
    stepSecond: 0,
    touchUi: true,
    color: 'primary',
    enableMeridian: false,
    disableMinute: false,
    hideTime: true
  }

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor() {
    this.now = moment();
    const next15MinuteInterval = Math.ceil(this.now.minute() / 15) * 15;
    this.now.minute(next15MinuteInterval).second(0);

    this.hours = [...Array(24).keys()].map(i => `${i < 10 ? '0' : ''}${i}`);
    this.hour = this.now.hour().toString();

    this.mins = ['00', '15', '30', '45'];
    this.min = this.now.minute().toString();

    if (this.min == '0') {
      this.min = `00`;
    }
  }

  ngOnInit(): void {
    this.control.patchValue(this.now, { emitEvent: false });

    this.control.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((values: any) => this.now = values);
  }

  changeHour($event: MatSelectChange): void {
    this.hour = $event.value;
    this.setDate();
  }

  changeMin($event: MatSelectChange): void {
    this.min = $event.value;
    this.setDate();
  }

  private setDate(): void {
    this.now = moment(this.now).set({
      'hour': parseInt(this.hour),
      'minute': parseInt(this.min)
    });
    this.control.patchValue(this.now, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

}
